import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {
  private timeout: any;
  private  sessionTimeoutTime: number=3600000;
  private sessionKey = 'lastActivityTime';

  constructor(private router: Router, private apiService: ApiService) {
    this.getSessionTimeOut()
  }

  setupSessionTimeout() {
    this.getSessionTimeOut()
    this.restoreSession();
    this.resetTimeout();
    this.addActivityListeners();
    this.handleTabEvents();
  }
  private addActivityListeners(): void {
    ['click', 'mousemove', 'keydown', 'scroll', 'touchstart'].forEach(event => {
      window.addEventListener(event, () => this.resetTimeout());
    });
  }


  getSessionTimeOut() {
    this.apiService.send2ServerRelative(`service_config/admin/SESSION-TIMEOUT`, false)
      .subscribe((data) => {
        if (data.status == 'ok' && data?.result?.length) {
          let time = Number(data.result[0]?.VALUE);
          if (time)
            this.sessionTimeoutTime = time;
        }
      });
  }

  private saveSessionActivity(): void {
    if (  this.sessionTimeoutTime  > 0 && this.apiService.getUserToken()) {
      const currentTime = Date.now();
      localStorage.setItem(this.sessionKey, currentTime.toString());
    }
  }

  private resetTimeout(): void {
    clearTimeout(this.timeout);
    this.saveSessionActivity();
    if (  this.sessionTimeoutTime  > 0 && this.apiService.getUserToken()) {
      this.timeout = setTimeout(() => this.logout(),  this.sessionTimeoutTime );
    }
  }

  private restoreSession(): void {
    const lastActivity = localStorage.getItem(this.sessionKey);
    if (lastActivity) {
      const elapsedTime = Date.now() - parseInt(lastActivity, 10);
      if (elapsedTime >   this.sessionTimeoutTime ) {
        this.logout();
      }
    }
  }

  private handleTabEvents(): void {  // Track when the tab becomes visible again
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.restoreSession();
        this.resetTimeout();
      }
    }); // Track when the user closes or refreshes the tab
    window.addEventListener('beforeunload', () => {
      this.saveSessionActivity();
    });
  }
  private logout() {
    this.apiService.clearUserToken();
    this.router.navigate(['authentication/login']);
  }
}